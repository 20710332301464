import React from 'react'
import logo from '../images/logo.png'
import phone from '../images/phone.png'

export default function Header() {
  return <div className = 'header'>
    <a href = '/'>
      <div className = 'header__logo'>
        <div className = 'header__logo-image'>
          <img src = {logo} alt = '' />
        </div>
        <div className = 'header__logo-text'>
          <div className = 'header__logo-text_upper-case'>
            АЗЪ
          </div>
          <div className = 'header__logo-text_lower-case'>
            фотокабинет
          </div>
        </div>       
      </div>
    </a>
    <div className = 'header__contacts'>
      <div className = 'header__contacts_phone-image'>
        <img src = {phone} alt = '' />
      </div>
      <div className = 'header__contacts_sign'>
        Запись на фотосессию
      </div>
      <div className = 'header__contacts_phone-number'>
        <a href = 'tel:+375447849995'> +375 44 784 99 95 </a>
      </div>
    </div>
  </div>
}
