import React from 'react'
import fb from '../images/fb.png'
import twitter from '../images/twitter.png'
import vk from '../images/vk.png'

export default function Footer() {

  const currentYear = new Date().getFullYear()

  return <div className = 'footer'>
    <div className = 'footer__company-info'>
      ООО «Харизма компани», УНП 191227798
    </div>
    <div className = 'footer__social'>
      <div className = 'copyrights'>
        Все права защищены, {currentYear}
      </div>
      <div className = 'social-links'>
        <a href = 'https://www.facebook.com/azphotokabinet'>
          <div className = 'social-links__icon'>
            <img src = {fb} alt = '' />
          </div>
        </a>
        <a href = 'https://twitter.com/azphotokabinet'>
          <div className = 'social-links__icon'>
            <img src = {twitter} alt = '' />
          </div>
        </a>
        <a href = 'http://vk.com/azphotokabinet'>
          <div className = 'social-links__icon'>
            <img src = {vk} alt  = '' />
          </div>
        </a>
      </div>      
    </div>
  </div>
}
