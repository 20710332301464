import React from 'react';
import Helmet from 'react-helmet';
import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';

const TITLE = 'Ambrotype'

export default function Layout({ children }) {
  return <div className = 'container'>
    <Helmet
      defaultTitle='Ambrotype'
    />
    <Header />
    <Menu />
    <div className = 'content'>
      {children}
    </div>
    <Footer />
  </div>
}
