import React from 'react'

export default function Menu() {
  return  <div className = 'menu'>
    <ul>
      <li>
        <a href = '/'> ГЛАВНАЯ </a>
      </li>
      <li>
        <a href = '/portfolio'> ПОРТФОЛИО </a>
      </li>
      <li>
        <a href = '/articles'> СТАТЬИ </a>
      </li>
      <li>
        <a href = '/about_us'> О НАС </a>
    </li>
    </ul>
  </div>
}
